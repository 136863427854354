#dashboard-container {
    padding: 0 36px 30px 36px;
    //height: 788px;
    background-color: #ddd;
    background-image: url("./images/mekke.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    

    @media only screen and (max-width: 768px) {
        padding: 0 10px 30px 10px;
        height: auto;

        .col{
            margin-top: 10px;
        }
    }

    #gradient-div {
        width: 100%;
        height: 300px;
        background-image: linear-gradient(#0942bd, rgba(149, 155, 176, 0));
        position: absolute;
        left: 0;
    }
}
