.z-index-4 {
  z-index: 4;
  position: relative;
}

.heart-l {
  & {
    padding-right: 20px;
  }

  &::after {
    content: "";
    background: url("./assets/images/heart-l.svg") no-repeat;
    width: 20px;
    height: 18px;
    position: absolute;
    margin-left: 4px;

    // position: relative;
    // top: 8px;
    // float: right;
  }
}

.heart-s {
  &.opacity {
    opacity: 0.7;
  }

  &::after {
    content: "";
    background: url("./assets/images/heart-s.svg") no-repeat;
    width: 12px;
    height: 11px;
    position: absolute;
    margin-left: 4px;
  }
}

.heart-multi {
  &::after {
    content: "";
    background: url("./assets/images/heart-multi.svg") no-repeat;
    width: 19px;
    height: 22px;
    position: absolute;
    margin-left: 4px;
  }
}

// .fade-out {
//   animation: fadeOut ease 1 forwards;
//   animation-duration: 2s;
// }

// .fade-out-5s {
//   animation: fadeOut ease 1 forwards;
//   animation-duration: 2s;
//   animation-delay: 5s;
// }

// .fade-out-10s {
//   animation-delay: 10s;
//   animation: fadeOut ease 1 forwards;
//   animation-duration: 2s;
// }

// @keyframes fadeOut {
//   0% {display:block; opacity:1;}
//     1% {display:none; opacity:1;}
//     100% {display: none; opacity:0;}
// }

#moreModalIframe {
  overflow: hidden;
  height: 602px;
  border: 0;
}

.modal-title {
  width: 100%;
}

.dropdown {
  &.narrow .dropdown-menu {
    min-width: 0;
  }

  .dropdown-menu {
    border-radius: 9px;
    //margin-top: 12px;
  }
}

.nme {
  pointer-events: none;
}

button:disabled.progress-bar-animated{
  opacity: 0.75;
  background-color: #a5c2f2 !important;
}

.modal-yeniden .modal-content{
  border-width: 0 !important;
    border-radius: 0 !important;
}


.sukranDiv{
  text-align: center;
    font-size: 20pt;
    font-weight: bold;
    padding: 15px;
}