#statistic-card-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 26px 28px;
    position: relative;

    .title {
        font: normal normal bold 16px/24px Roboto Condensed;
        letter-spacing: 0.15px;
        color: #707070;
        text-transform: uppercase;
    }

    .item {
        background-color: #f7f7f7;
        border-radius: 5px;
        padding: 20px 24px;
        width: 167px;
        height: 90px;
        margin-right: 6px;
        margin-bottom: 6px;
        width: 100%;

        .title2 {
            font: normal normal normal 14px/16px Roboto Condensed;
            color: #707070;
            display: block;
            opacity: 1 !important;
            text-transform: uppercase;
        }

        .counter {
            font: normal normal bold 32px/43px Roboto Slab;
            letter-spacing: -1.28px;
            color: #707070;
            display: block;
            opacity: 1 !important;
        }
    }
}
