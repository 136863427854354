#account-card-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 21px 54px 21px 33px;
    position: relative;

    @media only screen and (max-width: 768px) {
        padding: 21px 10px 21px 10px;
    }

    #title {
        text-transform: capitalize;
        font: normal normal bold 36px/48px Roboto Slab;
        color: #0942bd;
        margin-bottom: 13px;
    }

    #user {
        margin-bottom: 10px;

        #user-img {
            width: 48px;
            height: 48px;
            margin-right: 5px;
        }

        #username {
            text-transform: capitalize;
            font: normal normal bold 24px/28px Roboto Condensed;
            color: #0942bd;
        }
    }

    #details {
        padding-top: 8px;

        #telegram-container {
            margin-top: 10px;
            margin-bottom: 21px;

            #telegram-img {
                width: 24px;
                height: 24px;
                margin-right: 5px;
            }

            #title2 {
                text-transform: uppercase;
                font: normal normal normal 14px/24px Roboto Condensed;
                letter-spacing: 0.13px;
                color: #28a8e9;
            }

            #telegram-bot-info {
                font: normal normal normal 14px/24px Roboto Condensed;
                letter-spacing: 0.13px;
                color: #acacac;
            }
        }
    }
}