.form-button {


    &.btn {
        border-radius: 4px;
        text-transform: uppercase;
        padding: 12px 16px;
        border: none;
    }

    &.btn-primary {
        background-color: #348be1;
        color: #ffffff;
        font: normal normal bold 18px/16px Roboto Condensed;
        letter-spacing: 1.6px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12);

        &:hover {
            background-color: #3073b6;
        }
    }

    &.btn-light {
        background-color: #fff;
        font: normal normal bold 18px/16px Roboto Condensed;
        letter-spacing: 0.15px;
        color: #707070;
        box-shadow: none;
        
        &:hover {
            color: #80b1e2;
        }
    }

//     &.sending {
//         background-image: linear-gradient(
// 45deg
// , rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
//     background-size: 1rem 1rem;
// }
//         animation: progress-bar-stripes 1s linear infinite;
//         // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
//         // background-size: 400% 400%;
//         // animation: btn-sending-gradient 3s ease infinite;
//     }

    @keyframes btn-sending-gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
}
