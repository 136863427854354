#send-salavat-card-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 90px 100px;
    position: relative;

    #title {
        font: normal normal normal 20px/24px Roboto Condensed;
        color: #707070;
        text-transform: uppercase;
        text-align: right;
        display: block;
    }

    #total-count {
        font: normal normal bold 69px/91px Roboto Slab;
        letter-spacing: -2.76px;
        color: #707070;
        text-align: right;
        display: block;
    }

    @media (max-width: 576px) {
        & {
            padding: 20px 16px;
        }
        
        #total-count {
            word-wrap: break-word;
            word-break: break-word;
        }
    }

    select, input {
        margin-bottom: 15px;
        margin-top: 40px;
        border-radius: 4px 4px 0px 0px;
        height: 56px;
        background-color: #ecf4fc;
        border: none;
        border-bottom: 1px solid #80b5e9;
        outline: none;
        box-shadow: none;
        font-size: 16px;
        padding: 18px 16px;
        color: #68a9e8;

        &::placeholder {
            font: normal normal normal 16px/24px Roboto Condensed;
            letter-spacing: 0.15px;
            color: #348be1;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            font: normal normal normal 16px/24px Roboto Condensed;
            letter-spacing: 0.15px;
            color: #348be1;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            font: normal normal normal 16px/24px Roboto Condensed;
            letter-spacing: 0.15px;
            color: #348be1;
            opacity: 1;
        }
    }

    select {
        margin-bottom: 15px;
        margin-top: 40px;
        border-radius: 4px 4px 0px 0px;
        height: 56px;
        background-color: #ecf4fc;
        border: none;
        border-bottom: 1px solid #80b5e9;
        outline: none;
        box-shadow: none;
        font-size: 16px;
        padding: 18px 16px;
        color: #68a9e8;

        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='%2368a9e8' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 17px;

        &::placeholder {
            font: normal normal normal 16px/24px Roboto Condensed;
            letter-spacing: 0.15px;
            color: #348be1;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            font: normal normal normal 16px/24px Roboto Condensed;
            letter-spacing: 0.15px;
            color: #348be1;
            opacity: 1;
        }

        &::-ms-input-placeholder {
            font: normal normal normal 16px/24px Roboto Condensed;
            letter-spacing: 0.15px;
            color: #348be1;
            opacity: 1;
        }
    }

    button {
        border: 3px solid #3a8dde;
        border-radius: 40px;
        background-color: #fff;
        font: normal normal bold 20px/24px Roboto Condensed;
        color: #348be1;
        padding: 14px 41px;
        text-transform: uppercase;

        &:hover {
            background-color: #ecf4fc;
        }

        &:disabled{
            opacity: 0.25;
        }
    }
}