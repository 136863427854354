#country-section-container {
    #table-container {
        width: 820px;
        margin-top: 80px;

        @media only screen and (max-width: 840px) {
            width: 100%;
        }

        #mozaik {
            background-image: url("./muhammed-mozaik.svg");
            background-repeat: no-repeat;
            background-size: 100% 250%;
            padding-top: 58px;
            padding-bottom: 18px;
        }

        #title {
            text-align: center;
            font: normal normal bold 36px/48px Roboto Slab;
            letter-spacing: 0px;
            color: #03112f;
            display: block;
            font-size: 36px;

            @media only screen and (max-width: 768px) {
                font-size: 24px;
            }
        }

        #subtitle {
            text-align: center;
            font: normal normal normal 36px/48px Roboto Condensed;
            letter-spacing: 0px;
            color: #03112f;
            font-size: 20px;
            display: block;

            @media only screen and (max-width: 768px) {
                font-size: 18px;
            }
        }

        table {
            thead {
                text-transform: uppercase;
                background-color: #0942bd;
                font: normal normal bold 20px/24px Roboto Condensed;
                letter-spacing: 0px;
                color: #ffffff;
            }

            &.table-striped {
                tbody {
                    color: #707070;
                    font: normal normal normal 20px/25px Roboto Condensed;
                    text-transform: uppercase;
                    tr {
                        &:nth-of-type(odd) {
                            background-color: rgba(171, 171, 171, 0.05);
                        }
                        &:nth-of-type(even) {
                            background-color: rgba(171, 171, 171, 0.15);
                        }

                        .count {
                            font: normal normal bold 20px/25px Roboto Slab;
                        }
                    }
                }
            }

            th[scope="row"] {
                font: normal normal bold 20px/25px Roboto Condensed;
            }
        }

        #btn-show-all {
            color: #ffffff;
            background-color: #0942bd;
            border-radius: 40px;
            text-transform: uppercase;
            border: none;
            padding: 8px 22px;
            font: normal normal bold 18px/21px Roboto Condensed;
            margin-top: 40px;
            margin-bottom: 70px;
            outline: none;

            &:hover {
                background-color: #0b399c;
            }
        }
    }
}
