@import "../../../assets/scss/colors";
@import "../../../assets/scss/variables";

#home-container {
    background-color: $color-blue;
    height: calc(100vh - 122px);
    padding: 0 $main-jumbotron-padding $main-jumbotron-padding $main-jumbotron-padding;

    #muhammed-mozaik {
        position: relative;
        left: 21%;
        top: -7%;
        border: 1px solid yellow;
    }

    #main-slogan {
        width: 500px;
        height: 192px;
        border: 1px solid red;
        position: absolute;
        left: 10%;
        top: 54%;
    }

    #main-form {
        width: 600px;
        height: 500px;
        border: 1px solid black;
        position: absolute;
        right: 13%;
        top: 20%;
    }
}
