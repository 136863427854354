#menubar-container {
    padding: 34px 36px;
    background-color: #0942bd;

    #logo {
        width: 220px;
        height: 90px;

        @media only screen and (max-width: 768px) {
            width: 175px;
        }
    }

    #hamburger-menu {
        width: 36px;
        cursor: pointer;
        margin-left: 50px;
    }

    ul {
        margin-bottom: 0;

        @media only screen and (max-width: 768px) {
            padding: 0;
        }

        li {
            list-style-type: none;
        }
    }

    #lang-dropdown {
        margin-right: 30px;

        @media only screen and (max-width: 768px) {
            margin-right: 10px;
        }

        &.show {
            #lang-dropdown-toggle {
                opacity: 1 !important;
            }
        }

        #lang-dropdown-toggle {
            font: normal normal normal 18px/21px Roboto Condensed;
            color: #ffffff;
            color: #ffffff;
            opacity: 0.5;
        }
    }

    #user-dropdown.dropdown {
        // margin-right: 55px;

        &.show {

            #user-dropdown-toggle,
            svg .a {
                opacity: 1 !important;
            }
        }

        a {
            text-decoration: none;
            text-transform: uppercase;
            font: normal normal normal 18px/21px Roboto Condensed;
            color: #ffffff;
            padding: 0;
        }

        #user-dropdown-toggle {
            opacity: 0.5;

            svg {
                margin-left: 4px;
                margin-top: -5px;

                .a {
                    opacity: 0.5;
                }

                .b {
                    fill: none;
                }

                .c {
                    fill: #fff;
                }
            }
        }

        .dropdown-menu {
            padding: 22px 34px;
            border-radius: 9px;
            width: 315px;
            //margin-top: 12px;

            .nav-tabs {
                background-color: #348be1;
                font: normal normal normal 14px/16px Roboto Condensed;
                // letter-spacing: 1.25px;
                color: #ffffff;
                text-transform: uppercase;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

                .nav-item {
                    &:hover {
                        cursor: pointer;
                    }

                    .nav-link {
                        color: #98c4f0;
                        border: none;
                        padding: 16px 20px;
                        border-bottom: 3px solid transparent;

                        &.active {
                            color: #fff;
                            border-bottom: 3px solid #fff;
                            background-color: transparent;
                        }
                    }
                }
            }

            .tab-content {
                margin-top: 20px;
            }
        }
    }

    #sifremi-unuttum {
        font: normal normal normal 14px/24px Roboto Condensed;
        color: #707070;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .custom-control-label {
        font: normal normal normal 14px/24px Roboto Condensed;
        letter-spacing: 0.13px;
    }

    #muahmmed-mozaik {
        position: absolute;
        left: 20%;

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    #hamburger-menu-container {
        input {
            display: none;
        }

        input+label {
            position: absolute;
            top: 60px;
            right: 40px;
            height: 58px;
            width: 26px;
            z-index: 5;

            span {
                position: absolute;
                width: 100%;
                height: 2px;
                top: 28px;
                margin-top: -1px;
                left: 0;
                display: block;
                background: #fff;
                transition: 0.5s;
            }

            span:first-child {
                top: 4px;
            }

            span:last-child {
                top: 16px;
            }
        }

        label:hover {
            cursor: pointer;
        }

        input:checked+label {
            span {
                opacity: 0;
                top: 50%;
            }

            span:first-child {
                opacity: 1;
                transform: rotate(405deg);
            }

            span:last-child {
                opacity: 1;
                transform: rotate(-405deg);
            }
        }


        input~nav {
            background: #0942bd;
            position: fixed;
            top: -100px;
            left: 0;
            width: 100%;
            height: 100px;
            z-index: 3;
            transition: 0.5s;
            transition-delay: 0.5s;
            overflow: hidden;

            >ul.menu {
                text-align: center;
                position: absolute;
                top: 25%;
                left: 20%;
                right: 20%;

                @media only screen and (max-width: 768px) {
                    position: absolute;
                    top: 40%;
                    left: 0%;
                    width: 100%;
                }

                >li {
                    opacity: 0;
                    transition: 0.5s;
                    transition-delay: 0s;

                    >a {
                        text-decoration: none;
                        text-transform: uppercase;
                        font-weight: 700;
                        font-family: sans-serif;
                        display: block;
                        padding: 30px;
                        font: normal normal normal 40px/47px Roboto Condensed;
                        letter-spacing: 2px;
                        color: #ffffff;

                        @media only screen and (max-width: 768px) {
                            font: normal normal normal 32px/32px Roboto Condensed;
                            padding: 20px 30px;
                        }
                    }
                }
            }
        }

        input:checked~nav {
            height: calc(100vh + 100px);
            transition-delay: 0s;

            >ul {
                >li {
                    opacity: 1;
                    transition-delay: 0.5s;
                }
            }
        }
    }

    // &.menu-open ul.user {
    //     display: none;
    // }


    ul.user {
        margin: 9px 55px 0 0;
    }

    // .menu-logo{
    //     position: relative;
    // }

    @media (max-width: 575px) {

        ul.user {
            display: none !important;
            margin: 33px 40px 0 0;
            animation: fadeIn 0.5s;
            z-index: 4;
            position: relative;
        }

        &.menu-open {
            ul.user {
                display: flex !important;
            }

            .menu-logo {
                visibility: hidden;
                width: 0;
            }
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        #user-dropdown.dropdown .dropdown-menu {
            right: -45px;
        }
    }

    nav .menu-logo {
        margin-top: 100px;
        padding: 34px 36px;
    }
}