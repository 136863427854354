#main-section-container {
    background-color: #0942bd;
    // height: calc(100vh - 158px);
    padding: 0 36px;

    @media only screen and (max-width: 992px) {
        padding: 0 70px 0 36px;
    }

    #dash {
        position: absolute;
        right: 153px;
        top: 25px;

        @media only screen and (max-width: 992px) {
            right: -44px;
        }
    }

    #slogan {
        font: normal normal bold 36px/48px Roboto Slab;
        letter-spacing: 0px;
        opacity: 1;
        margin-left: 48px;
        min-height: 192px;
        position: relative;
        //z-index: 4;

        .sectionSon {
            // font-size: 24px;
            font: normal normal bold 26px/32px Roboto Slab;
            color: #ffffff;
        }

        @media only screen and (max-width: 992px) {
            font-size: 24px;
            position: initial;
            z-index: 3;
        }

        @media only screen and (max-width: 768px) {
            margin-left: 0;
            margin-top: 20px;
        }

        @media only screen and (max-width: 576px) {
            width: 300px;
        }

        .section1 {
            color: #348be1;
        }

        .section2 {
            color: #ffffff;
        }
    }

    #hicri-date-time {
        padding-right: 22px;
        margin-left: 82px;
        color: white;
        text-transform: uppercase;
        text-align: left;
        padding-top: 71px;

        #divider {
            width: 86px;
            height: 7.78px;
            background: #348be1 0% 0% no-repeat padding-box;
            opacity: 0.25;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .label1 {
            display: block;
            font: normal normal normal 30px/15px Roboto Condensed;
            letter-spacing: -1.2px;

            @media only screen and (max-width: 992px) {
                display: inline;
                letter-spacing: inherit;
                font-size: 19px;
            }
        }

        .label2 {
            font: normal normal normal 14px/15px Roboto Condensed;
            letter-spacing: -0.48px;
            margin-top: 15px;
        }

        @media only screen and (max-width: 992px) {
            padding-right: 0;
            margin-left:0;
            text-align: right;
            padding-top: 0;
            margin-right: -52px;
        }
    }

    #form-container {
        /* width: 467px;
        height: 454px; */
        padding-bottom: 15px;
        // border-width: 5px;
        // border: 5px dotted #ffffff;
        // border-bottom-right-radius: 10px;
        // border-top-right-radius: 10px;
        // border-left: none;

        >div:first-child {
            width: 390px;
            float: right;

            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }

        .item {
            margin-right: 5px;
            text-align: right;

            #label1 {
                display: block;
                text-transform: uppercase;
                font: normal normal normal 20px/24px Roboto Condensed;
                letter-spacing: 0px;
                color: #ffffff;

                @media only screen and (max-width: 992px) {
                    font-size: 14px;
                }
            }

            #label2 {
                font: normal normal bold 62px/76px Roboto Slab;
                letter-spacing: -2.76px;
                color: #ffffff;
                margin-right: -19px;

                @media only screen and (max-width: 992px) {
                    font-size: 49px;
                }
            }

            #label3 {
                font: normal normal bold 62px/49px Roboto Slab;
                letter-spacing: -2.76px;
                color: #348be1;

                @media only screen and (max-width: 992px) {
                    font-size: 49px;
                }
            }
        }

        #input-container {
            margin-top: 25px;

            input,
            select {
                margin-bottom: 12px;
                border-radius: 4px 4px 0px 0px;
                height: 51px;
                background-color: #093fb5;
                border: none;
                border-bottom: 1px solid #597ece;
                outline: none;
                box-shadow: none;
                font-size: 16px;
                // padding: 18px 16px;
                color: #fff;

                &::placeholder {
                    font: normal normal normal 16px/24px Roboto Condensed;
                    letter-spacing: 0.15px;
                    color: #348be1;
                    opacity: 1;
                }

                &::-ms-input-placeholder {
                    font: normal normal normal 16px/24px Roboto Condensed;
                    letter-spacing: 0.15px;
                    color: #348be1;
                    opacity: 1;
                }

                &::-ms-input-placeholder {
                    font: normal normal normal 16px/24px Roboto Condensed;
                    letter-spacing: 0.15px;
                    color: #348be1;
                    opacity: 1;
                }
            }

            select {
                -webkit-appearance: none;
                -moz-appearance: none;
                background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-repeat: no-repeat;
                background-position-x: 98%;
                background-position-y: 17px;
            }

            button {
                text-transform: uppercase;
                margin-top: 19px;
                border: 3px solid #ffffff;
                border: 3px solid #ffffff;
                border-radius: 40px;
                font: normal normal bold 27px/32px Roboto Condensed;
                letter-spacing: 0px;
                color: #ffffff;
                background-color: transparent;
                padding: 14px 36px;

                &:not(.disabled):hover {
                    background-color: #0b399c;
                }

                &:disabled{
                    opacity: 0.25;
                }

                .spinner-border {
                    position: absolute;
                    margin: 4px 0px 0px 5px;
                    width: 1.5rem;
                    height: 1.5rem;
                    border-width: 3px;
                }

                @media only screen and (max-width: 992px) {
                    padding: 5px 20px;
                    font-size: 14px;
                    margin-top: 30px;

                    & .spinner-border {
                        margin: 8px 0px 0px 2px;
                        width: 1rem;
                        height: 1rem;
                        border-width: 2px;
                    }
                }
            }

            #location {
                font: normal normal normal 16px/20px Roboto;
                letter-spacing: 0.29px;
                color: #348be1;
                margin-top: 20px;
            }
        }

        #progressbar-container {
            margin-right: -28px;

            // excess height to improve interactive area / accessibility
            $height: 30px;
            $thumb-height: 16px;
            $track-height: 8px;

            // colours
            $upper-color: #1454c6;
            $lower-color: #e80813;
            $thumb-color: #e80813;
            $thumb-hover-color: #e80813;

            $upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
            $lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

            // Webkit cannot style progress so we fake it with a long shadow
            // on the thumb element
            @function webkit-slider-thumb-shadow($i: 1) {
                $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};

                @if $i==1 {
                    @for $k from 2 through 1000 {
                        $val: #{$val},
                        webkit-slider-thumb-shadow($k);
                    }
                }

                @return $val;
            }

            .e-range {
                display: block;
                appearance: none;
                width: 100%;
                margin: 0;
                height: $height;
                overflow: hidden;
                cursor: pointer;
                background-color: transparent;

                &:focus {
                    outline: none;
                }
            }

            // Webkit
            .e-range::-webkit-slider-runnable-track {
                width: 100%;
                height: $height;
                background: $lower-background;
            }

            .e-range::-webkit-slider-thumb {
                position: relative;
                appearance: none;
                height: $thumb-height;
                width: $thumb-height;
                background: $thumb-color;
                border-radius: 100%;
                border: 2px white solid;
                top: 50%;
                margin-top: (-$thumb-height/2);
                box-shadow: webkit-slider-thumb-shadow();
                transition: background-color 150ms;
            }

            // Firefox
            .e-range::-moz-range-track,
            .e-range::-moz-range-progress {
                width: 100%;
                height: $height;
                background: $upper-background;
            }

            .e-range::-moz-range-progress {
                background: $lower-background;
            }

            .e-range::-moz-range-thumb {
                appearance: none;
                margin: 0;
                height: $thumb-height - 4;
                width: $thumb-height - 4;
                background: $thumb-color;
                border-radius: 100%;
                border: 2px white solid;
                transition: background-color 150ms;
            }

            // // Internet Exploder
            // .e-range::-ms-track {
            //     width: 100%;
            //     height: $height;
            //     border: 2px white solid;
            //     // color needed to hide track marks
            //     color: transparent;
            //     background: transparent;
            // }

            // .e-range::-ms-fill-lower {
            //     background: $lower-background;
            // }

            // .e-range::-ms-fill-upper {
            //     background: $upper-background;
            // }

            // .e-range::-ms-thumb {
            //     appearance: none;
            //     height: $thumb-height;
            //     width: $thumb-height;
            //     background: $thumb-color;
            //     border-radius: 100%;
            //     border: 2px white solid;
            //     transition: background-color 150ms;
            //     // IE Edge thinks it can support -webkit prefixes
            //     top: 0;
            //     margin: 0;
            //     box-shadow: none;
            // }

            .e-range:hover,
            .e-range:focus {
                &::-webkit-slider-thumb {
                    background-color: $thumb-hover-color;
                }

                &::-moz-range-thumb {
                    background-color: $thumb-hover-color;
                }

                &::-ms-thumb {
                    background-color: $thumb-hover-color;
                }
            }
        }
    }

    .Cursor--blinking {
        display: none;
    }

    input:read-only {
        color: #aaa !important;
    }
}