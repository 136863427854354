#footer-container {
    text-transform: uppercase;
    color: #fff;
    font: normal normal normal 18px/24px Roboto Condensed;

    .dropdown span {
        cursor: pointer;
    }

    #section1 {
        background-color: #0942bd;
        width: 100%;
        // height: 332px;
        padding: 53px 36px;

        #logo {
            width: 200px;
            height: 144px;
            margin: 0 20px;

            // @media only screen and (max-width: 768px) {
            //     margin: 0 auto;
            //     margin-bottom: 30px;
            // }
        }

        a,
        .dropdown {
            color: #fff;
            margin-bottom: 20px;
            margin: 0 5px;
        }


        img {
            margin-right: 16px;
        }

        ul {
            @media only screen and (max-width: 768px) {
                margin-bottom: 0;
            }

            li {
                text-transform: uppercase;
                list-style: none;
                color: #fff;
                font: normal normal normal 18px/24px Roboto Condensed;
                margin-bottom: 20px;

                a {
                    color: #fff;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            height: auto;
            text-align: center;
            padding: 53px 5px;
            
            img {
                margin-right: 5px;
            }

            #org span{
                display: block;
                margin-bottom: 5px;
            }
        }
    }

    #section2 {
        background-color: #2b2b2b;
        width: 100%;
        height: 38px;
    }
}