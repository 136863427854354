.form-input-container {
    border: 2px solid #3a8dde;
    border-radius: 4px;
    height: 45px;
    margin-bottom: 20px;

    &.no-height {
        height: auto !important;
    }

    input,
    select,
    textarea {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font: normal normal normal 16px/24px Roboto Condensed;
        letter-spacing: 0.15px;
        color: #000000bc;
        position: relative;
        bottom: 23px;
        background-color: transparent;
    }

    input {
        padding: 12px 16px;
    }

    select {
        padding-left: 16px;
    }

    textarea {
        resize: none;
    }

    label {
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: 0.4px;
        color: #348be1;
        background-color: #fff;
        margin: 0;
        position: relative;
        bottom: 15px;
        left: 13px;
        padding: 0px 2px;
        z-index: 1;
    }
}
