#social-section-container {
    background-color: #f7f7f7;
    background-image: url("./share-24px.svg");
    background-repeat: no-repeat;
    background-size: 55% 100%;
    background-position-y: -270px;
    background-position-x: 90px;

    #text-container {
        margin-top: 70px;
        @media only screen and (max-width: 992px) {
            padding-left: 50px;
            padding-right: 50px;
        }

        #title {
            font: normal normal bold 36px/48px Roboto Slab;
            letter-spacing: 0px;
            color: #03112f;
            font-size: 36px;
            margin-bottom: 16px;

            @media only screen and (max-width: 992px) {
                font-size: 24px;
            }
        }

        #description {
            letter-spacing: 0px;
            color: #03112f;
            font-size: 20px;
            font: normal normal normal 20px/24px Roboto Condensed;
            margin-bottom: 36px;

            @media only screen and (max-width: 992px) {
                font-size: 16px;
            }
        }

        #social-media {
            margin-bottom: 37px;

            button {
                margin-right: 10px;
            }
        }

        #hadis {
            color: #5e9151;
            font-family: Roboto Condensed;
            width: 426px;
            letter-spacing: 0px;
            text-align: center;

            @media only screen and (max-width: 992px) {
                width: 340px;
            }

            span {
                display: block;
                font-size: 24px;
                margin-bottom: 15px;

                @media only screen and (max-width: 992px) {
                    font-size: 18px;
                }
            }

            small {
                font-size: 14px;
            }
        }
    }
}
