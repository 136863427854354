#live-section-container {
    width: 100%;
    height: 500px;
    background-color: #0942bd;
    -moz-box-shadow: inset 0 -35px 100px -10px #06225c;
    -webkit-box-shadow: inset 0 -35px 100px -10px #06225c;
    box-shadow: inset 0 -35px 100px -10px #06225c;
    padding-top: 38px;
    overflow: hidden;
    display: inline-table;

    #title-arabic {
        color: #348be1;
        font-size: 32px;
        font: normal normal bold 32px/39px "sans-serif";
        text-align: center;
        margin-bottom: 22px;
        text-align: center;
        display: block;
    }

    #title {
        font: normal normal bold 36px/48px Roboto Slab;
        color: #ffffff;
        display: block;
        text-align: center;

        @media only screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    #content {
        padding: 20px 10px;
        text-align: center;

        .item {
            color: #fff;
            text-transform: uppercase;
            font-family: "Roboto Condensed";
            font-size: 19px;
            display: inline-block;
            
            &.opacity {
                // opacity: 0.5;        
                color: #84A1DE;
            }

            @media only screen and (max-width: 768px) {
                font-size: 15px;
            }

            &.light {
                color: #2776d7 !important;
            }

            .name {
                font-weight: bold;
            }

            .info {
                margin-right: 15px;
            }
        }
    }
}