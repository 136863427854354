#contact-container {
    position: relative;
    background-image: url("./mekke.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .info-card {
        background-color: #fff;
        padding: 52px 70px;
        display: inline-block;
        width: 50%;

        margin: 0 auto;
        left: 25%;
        top: 10%;
        position: relative;

        @media only screen and (max-width: 768px) {
            // top: 15%;
            left: 5%;
            width: 90%;
            padding: 10px 10px;
            opacity: 0.8;
        }

        .title {
            font: normal normal bold 32px/32px Roboto Slab;
            color: #03112f;
            margin-bottom: 24px;
            display: block;
            text-align: center;
        }
    }
}