#salavat-about-container {
    position: relative;

    #section1,
    #section2 {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    #section1 {
        height: calc(100vh - 140px);
        background-image: url("./images/about.png");
        padding-top: 47px;

        #title {
            display: block;
            font: normal normal bold 36px/48px Roboto Slab;
            color: #03112f;
            margin-bottom: 16px;
            text-align: center;
        }

        #description {
            display: block;
            font: normal normal normal 20px/24px Roboto Condensed;
            color: #03112f;
            text-align: center;
        }

        .info-card {
            position: relative;
            left: calc(30vw - 240px);
            top: calc(100vh - 480px);
        }

        @media only screen and (max-width: 768px) {
            padding-top: 0;
        }
    }

    #section2 {
        height: 100vh;
        background-image: url("./images/about2.png");

        .info-card {
            position: relative;
            left: calc(54vw - 444px);
            top: calc(100vh - 450px);
        }
    }

    #section3 {
        height: 100vh;
        background-color: #f7f7f7;
        padding: 101px 444px 92px 240px;

        .info-card {
            position: relative;
            top: calc(100vh - 680px);
        }

        @media only screen and (max-width: 768px) {
            padding: 0;
            height: auto;
        }
    }

    .info-card {
        background-color: #fff;
        padding: 52px 70px;
        display: inline-block;
        width: 596px;        

            @media only screen and (max-width: 768px) {
                position: inherit !important;
                margin: 20px 20px;
                width: auto;                
                padding: 20px 20px;
                opacity: 0.8;
            }

        .title {
            font: normal normal bold 24px/32px Roboto Slab;
            color: #03112f;
            margin-bottom: 5px;
            display: block;
        }

        .subtitle {
            font: normal normal normal 18px/21px Roboto Condensed;
            color: #03112f;
            margin-bottom: 26px;
            display: block;
        }
        p {
            font: normal normal normal 16px/19px Roboto Condensed;
            color: #03112f;
        }

        .source {
            margin-top: 13px;
            font: normal normal normal 12px/14px Roboto Condensed;
            color: #707070;
        }
    }
}
