#map-section-container {
    width: 100%;
    height: 542px;
    background-color: #03112f;
    padding-top: 45px;

    #map-item {
        width: 790px;
        height: 570px;
        background-image: url("./muhammed-mozaik.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        @media only screen and (max-width: 820px) {
            width: 100%;
        }

        iframe {
            width: 795px;
            height: 407px;
            border: none;

            @media only screen and (max-width: 820px) {
                width: 100%;
                padding: 0 20px;
            }
        }

        .title {
            font: normal normal normal 36px/42px Roboto Condensed;
            color: #ffffff;
            text-transform: uppercase;

            .bold {
                font-weight: bold;
            }

            @media only screen and (max-width: 768px) {
                font-size: 22px;
            }
        }
    }
}
